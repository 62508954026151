var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "front-page-body" },
    [
      _c("page-header", {
        attrs: {
          "disease-group": _vm.$parent.diseaseGroup,
          "front-contents": _vm.$parent.frontContents,
          page: "front",
        },
      }),
      _vm.$parent.diseaseGroup
        ? _c("div", [
            _c("div", { staticClass: "fluid" }, [
              _c(
                "div",
                {
                  class:
                    "front-top-banner-" +
                    _vm.$parent.diseaseGroup.name +
                    "kp front-top-banner",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "container",
                      staticStyle: { display: "block !important" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "front-logo-wrapper" }, [
                            _vm.$parent.frontContents.field_front_logo
                              ? _c("img", {
                                  staticClass: "front-logo-img",
                                  attrs: {
                                    src:
                                      "https://kp4cd.org/sites/default/files/vueportal/" +
                                      _vm.$parent.frontContents
                                        .field_front_logo,
                                  },
                                })
                              : _vm._e(),
                            _c("span", {
                              class:
                                "front-logo-tagline front-logo-tagline-" +
                                _vm.$parent.diseaseGroup.name +
                                "kp",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$parent.frontContents.field_tagline
                                ),
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm.$parent.diseaseGroup.name == "a2f"
                        ? _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { "margin-top": "25px" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-8 offset-md-2" },
                                [
                                  _c("h4", {
                                    staticStyle: { color: "#fff" },
                                    attrs: { align: "center" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        !!_vm.$parent.diseaseInSession
                                          ? "<small>Current focus:</small> " +
                                              _vm.$parent.diseaseInSession
                                          : "Set system focus"
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      class: !_vm.$parent.diseaseInSession
                                        ? "hidden"
                                        : "",
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "btn btn-sm btn-light",
                                          attrs: {
                                            href: "javascript:;",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$parent.showHideElement(
                                                "disease_systems_tree"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s("Change focus"))]
                                      ),
                                    ]
                                  ),
                                  _vm.$store.state.bioPortal.diseaseSystems
                                    .length > 0 &&
                                  _vm.$parent.phenotypes.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "disease-systems-trees-wrapper",
                                          class: !!_vm.$parent.diseaseInSession
                                            ? "hidden"
                                            : "",
                                          attrs: { id: "disease_systems_tree" },
                                        },
                                        [
                                          _c("disease-systems", {
                                            attrs: {
                                              page: "front",
                                              diseases:
                                                _vm.$store.state.bioPortal
                                                  .diseaseSystems,
                                              "disease-groups":
                                                _vm.$store.state.bioPortal
                                                  .diseaseGroups,
                                              phenotypes:
                                                _vm.$parent.phenotypes,
                                              "phenotype-correlation":
                                                _vm.$store.state
                                                  .phenotypeCorrelation,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.$store.getters["bioPortal/isRootPortal"]
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12 portal-front-tabs" },
                              [
                                _c(
                                  "b-tabs",
                                  {
                                    attrs: {
                                      "content-class": "mt-3",
                                      align: "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title:
                                            "Gene, region, variant, phenotype or tissue",
                                          active: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-8 offset-md-2",
                                            attrs: { align: "center" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "single-search-wrapper",
                                              },
                                              [
                                                _c("research-single-search", {
                                                  attrs: {
                                                    "single-search-config":
                                                      null,
                                                    phenotypes:
                                                      _vm.$parent
                                                        .phenotypesInSession,
                                                    utils: _vm.$parent.utilsBox,
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "region-search-examples a2f-region-search-examples",
                                                  },
                                                  [
                                                    _c("documentation", {
                                                      attrs: {
                                                        name: "home.example",
                                                        contentMap:
                                                          _vm.$store.state
                                                            .bioPortal
                                                            .documentations,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: "Disease-specific portals",
                                        },
                                      },
                                      [
                                        _c("disease-group-select", {
                                          attrs: {
                                            "disease-groups":
                                              _vm.$store.state.bioPortal
                                                .diseaseGroups,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      !_vm.$store.getters["bioPortal/isRootPortal"]
                        ? [
                            _c(
                              "div",
                              { staticClass: "row front-search-section" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-md-8 offset-md-2",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "single-search-wrapper" },
                                      [
                                        _c(
                                          "h4",
                                          { staticStyle: { color: "#fff" } },
                                          [
                                            _vm._v(
                                              " Search gene, variant, region, phenotype or tissue "
                                            ),
                                          ]
                                        ),
                                        _c("research-single-search", {
                                          attrs: {
                                            "single-search-config": null,
                                            phenotypes:
                                              _vm.$parent.phenotypesInSession,
                                            utils: _vm.$parent.utilsBox,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "region-search-examples a2f-region-search-examples",
                                          },
                                          [
                                            _c("documentation", {
                                              attrs: {
                                                name: "home.example",
                                                contentMap:
                                                  _vm.$store.state.bioPortal
                                                    .documentations,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c("div", { attrs: { id: "stats" } }, [
                        _c(
                          "div",
                          { staticClass: "stats" },
                          _vm._l(
                            _vm.$parent.statsArray,
                            function (stat, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "stat" },
                                [
                                  _c("div", { staticClass: "icon" }, [
                                    _c("img", {
                                      attrs: {
                                        src: `/images/icons/stats/${stat.icon}.svg`,
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "count" }, [
                                      _vm._v(" " + _vm._s(stat.value) + " "),
                                    ]),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(" " + _vm._s(stat.display) + " "),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "container static-content-section" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    class:
                      _vm.$parent.diseaseGroup.name == "a2f"
                        ? "col-md-8"
                        : "col-md-7",
                  },
                  [
                    _vm.$parent.kPortals &&
                    _vm.$parent.diseaseGroup.name == "a2f"
                      ? _c("div", [
                          _c("h2", [_vm._v("Community Knowledge Portals")]),
                          _c("p"),
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(_vm.$parent.kPortals, function (portal) {
                              return _c(
                                "div",
                                { key: portal.title, staticClass: "k-portal" },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(portal.body),
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "kp-title",
                                    domProps: {
                                      innerHTML: _vm._s(portal.title),
                                    },
                                  }),
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: portal.field_portal_address,
                                        },
                                      },
                                      [_vm._v("Visit portal")]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$parent.frontContents.field_about_portal
                        ),
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.$parent.diseaseGroup.name == "a2f"
                        ? "col-md-4"
                        : "col-md-5",
                  },
                  [
                    _vm.$parent.oldStats && _vm.$parent.pageDescription != null
                      ? _c("research-page-description", {
                          attrs: {
                            content: _vm.$parent.pageDescription,
                            utils: _vm.$parent.utilsBox,
                          },
                        })
                      : _vm._e(),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$parent.frontContents.field_about_project
                        ),
                      },
                    }),
                    _c("news-feed-section", {
                      attrs: {
                        "disease-group": _vm.$parent.diseaseGroup,
                        "news-feed": _vm.$store.state.kp4cd.newsFeed,
                      },
                    }),
                    _c(
                      "h2",
                      {
                        staticStyle: {
                          "font-family": "'Oswald'",
                          "font-size": "30px",
                          "margin-top": "5px",
                        },
                      },
                      [_vm._v(" Stay in touch ")]
                    ),
                    _vm._m(0),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("page-footer", {
        attrs: { "disease-group": _vm.$parent.diseaseGroup },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://docs.google.com/forms/d/e/1FAIpQLSdA8Wn6QDLNLoUejq-BJrPW7SFAM7DsFggu8iPOIhOP0WS2Tw/viewform",
            target: "_blank",
          },
        },
        [_vm._v("Sign up")]
      ),
      _vm._v(" for emailed updates, newsletters, and webinar invitations. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }